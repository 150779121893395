import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [formData, setFormData] = useState({ identifier: '', password: '' });
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/login/`, formData);
            localStorage.setItem('user_name', formData.identifier);
            localStorage.setItem('access_token', response.data.access);
            // alert('Login successful');
            navigate('/home');
        } catch (error) {
            alert(error.response?.data?.error || 'Login failed');
        }
    };

    return (
        <div className="container d-flex justify-content-center align-items-center " style={{marginTop:50}}>
            <div className="card p-4 shadow-lg" style={{ width: '400px' }}>
                <h2 className="text-center mb-4">Login</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className="form-label" >Username or Email</label>
                        <input type="text" className="form-control" name="identifier" placeholder="Enter your username or email" onChange={handleChange} required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Password</label>
                        <input type="password" className="form-control" name="password" placeholder="Enter your password" onChange={handleChange} required />
                    </div>
                    <button type="submit" className="btn btn-success w-100">Login</button>
                </form>
                <p className="text-center mt-3">Don't have an account? <a href="/signup">Sign up</a></p>
            </div>
        </div>
    );
};

export default Login;
