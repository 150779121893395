import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const Signup = () => {
    const [formData, setFormData] = useState({ name: '', email: '', password: '' });
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log("form",formData)

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/signup/`, formData);
            // console.log("response",response.data)
            // alert(response.data.message);
            navigate('/login');
        } catch (error) {
            // console.log("response",error.response.data)
            alert(error.response?.data?.error || 'Signup failed');
        }
    };

    return (
        <div className="container d-flex justify-content-center align-items-center " style={{marginTop:50}}>
            <div className="card p-4 shadow-lg" style={{ width: '400px' }}>
                <h2 className="text-center mb-4">Sign Up</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className="form-label">Name</label>
                        <input type="text" className="form-control" name="name" placeholder="Enter your name" onChange={handleChange} required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Email</label>
                        <input type="email" className="form-control" name="email" placeholder="Enter your email" onChange={handleChange} required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Password</label>
                        <input type="password" className="form-control" name="password" placeholder="Enter your password" onChange={handleChange} required />
                    </div>
                    <button type="submit" className="btn btn-primary w-100">Sign Up</button>
                </form>
                <p className="text-center mt-3">Already have an account? <a href="/login">Login</a></p>
            </div>
        </div>
    );
};

export default Signup;
