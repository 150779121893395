import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap Icons

const Home = () => {
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');

    useEffect(() => {
        // Fetch the stored username (modify based on actual storage)
        const storedUser = localStorage.getItem('user_name');
        if (storedUser) {
            setUserName(storedUser);
        } else {
            navigate('/login'); // Redirect if not logged in
        }
    }, [navigate]);

    const handleLogout = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user_name');
        navigate('/');
    };

    return (
        <div style = {{marginTop:-70}}>
            {/* Navbar */}
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                <img
                    src="/media/U2.png"
                    alt="Logo"
                    width="60"
                    height="50"
                    style={{ borderRadius: "10px" }}
                    className="d-inline-block align-top"
                    />
                    
                    <div className="ms-auto d-flex align-items-center">
                        <span className="text-dark me-3">{userName}</span>
                        <i className="bi bi-person-circle text-dark me-2" style={{ fontSize: '2rem' }}></i>
                        
                        <button className="btn btn-danger" style = {{marginTop:-10}} onClick={handleLogout}>Logout</button>
                    </div>
                </div>
            </nav>

            {/* Main Content */}
            <div className="container text-center mt-5">
                {/* <h2>Welcome, {userName}!</h2>
                <p>This is your dashboard.</p> */}
            </div>
        </div>
    );
};

export default Home;
