import React from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Import useLocation
import "./navbar.css";

const CustomNavbar = () => {
  const location = useLocation(); // Get the current location

  const navigate = useNavigate();

  const signupFunc = () => {
    navigate('/signup');
  }
  const loginFunc = () => {
    navigate('/login');
  }

  return (
    <Navbar bg="light shadow" expand="lg" className="px-4 fixed-top ">
      <Navbar.Brand className="d-flex align-items-center">
        <img
          src="/media/U2.png"
          alt="Logo"
          width="60"
          height="50"
          style={{ borderRadius: "10px" }}
          className="d-inline-block align-top"
        />
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />

      <Navbar.Collapse id="basic-navbar-nav ">
        <Nav className="mx-auto">
          {/* Add active class based on location.pathname */}
          <Nav.Link as={Link} to="/" className={location.pathname === "/" ? "active" : "notactive"}>
            Home
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/about"
            className={location.pathname === "/about" ? "active" : "notactive"}
          >
            About Us
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/contact"
            className={location.pathname === "/contact" ? "active" : "notactive"}
          >
            Contact Us
          </Nav.Link>
        </Nav>
        <div>
          <Nav className="ms-auto">
            <Button variant="outline-dark" className="me-2 mb-2" onClick={loginFunc}>
              Log in
            </Button>
            <Button variant="dark" className="me-2 mb-2" onClick={signupFunc}>
              Sign up
            </Button>
          </Nav>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default CustomNavbar;
